import * as React from 'react'
import css from './ProductDetailsRow.scss'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import MorPriceDisplay from './MorPriceDisplay/MorPriceDisplay'
import CustomProductBadge from '../Search/CustomProductBadge'
import { IMorUnbxdProduct } from '../../../Utils/unbxdUtils'
import { useState } from 'react'
import ShareModal from './ShareModal'
const ShareButton = '/static/share-button.png'

export interface IProductDetailRowsProps {
  product: IProduct
  storeCode?: string
  displayRatingSummary: boolean
}

const TopProductInfo = (props: IProductDetailRowsProps) => {
  const { product, storeCode, displayRatingSummary } = props
  const [showShare, setShowShare] = useState(false)

  return (
    <>
      <div className={css.thumbnailBadges}>
        <CustomProductBadge
          style={{ marginRight: '7px' }}
          value={(product as IMorUnbxdProduct).DELIVERY_QUALIFICATION}
        />
        <CustomProductBadge value={(product as IMorUnbxdProduct).VENDOR_TYPE} />
      </div>
      <div>
        <h1 className={css.nameAttribute}>{product.name}</h1>
        <div className={css.priceAttribute}>
          <MorPriceDisplay product={product} storeCode={storeCode} showSeePriceInCartLabel />
        </div>
        <span className={css.reviewAndShare}>
          {displayRatingSummary && (
            <div className={css.productReview} data-bv-show="rating_summary" data-bv-product-id={product.sku}></div>
          )}
          <div className={css.icon} onClick={() => setShowShare(true)}>
            <img src={ShareButton} alt="Share" />
            <div aria-label="Share (opens pop-up)" className={css.shareButton} id="Share">
              Share
            </div>
          </div>
        </span>
      </div>
      {showShare && <ShareModal onClose={() => setShowShare(false)} product={product} />}
    </>
  )
}

export default TopProductInfo
